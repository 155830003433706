import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {  setCart, setIsAuth, setIsLoading, setLoadedHeader, setSessionData, setUserOrderId } from '../redux.actions/items.actions';
import { decryptJWT } from '../helpers/jwtFunctions';
import { removeAuthCookie } from '../helpers/authCookie';
import { useStorage } from './useStorage';
import useClearLocalStorageOnSignal from './useClearLocalStorageOnSignal ';
import { timerAlert } from '../helpers/alerts';
import updateCartFromDB from '../helpers/updateCartFromDB';
import AxiosService from '../services/AxiosServices';
import { useRouter } from 'next/navigation';
import getVoucherInfo from '../helpers/getVoucherInfo';


const useSetIsAuth = () => {

    const dispatch = useDispatch();
    const storage =  useStorage();
    const axiosService = new AxiosService();
    const router = useRouter();
    useClearLocalStorageOnSignal();


    useEffect(() => {
        const setIsAuthState = async () => {
            const token = storage.getLocal('token');

            if (!token) {
                dispatch(setIsAuth(false));
                storage.clearLocal();
                removeAuthCookie();
                dispatch(setLoadedHeader(true));
                return;
            }

            const isTokenValid = await decryptJWT(token);

            if (!isTokenValid) {
                dispatch(setLoadedHeader(true));
                await timerAlert(
                    'Error',
                    'La sesión ha expirado',
                    'error'
                );
                dispatch(setIsAuth(false));
                storage.clearLocal();
                removeAuthCookie();
                dispatch(setSessionData({ userId: '', role: '' }));
                setTimeout(() => {
                    window.location.href = '/auth';
                }, 2000);
                // router.push('/auth');

                return;
            } else {
                dispatch(setLoadedHeader(true));
                dispatch(setIsAuth(true));
                dispatch(setSessionData(isTokenValid.payload.id, isTokenValid.payload.role));
                document.body.classList.add(`${isTokenValid.payload.role}`);
                
                if (isTokenValid.payload.role !== 'super_admin' && isTokenValid.payload.role !== 'admin' && isTokenValid.payload.role !== 'webmaster') {
                    dispatch(setIsLoading(true));
                    
                    const userOrders = await axiosService.getDataById( `/orders/user`, isTokenValid.payload.id );
                    if(userOrders && userOrders.length > 0){
                        const userId = userOrders[0].user_id;
                        dispatch(setUserOrderId(userId));
                    }
                    
                    const new_cart = await updateCartFromDB(userOrders);
                    if (new_cart) {dispatch(setCart(new_cart, userOrders[0].total, userOrders[0].id));}
                    dispatch(setIsLoading(false));
                    

                }

                
            }
        };

        setIsAuthState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);



};

export default useSetIsAuth;