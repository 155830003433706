'use client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsAuth } from '../redux.actions/items.actions';
import { timerAlert } from '../helpers/alerts';

const useClearLocalStorageOnSignal = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const checkTokenAndClearStorage = async () => {
            if (typeof window !== 'undefined') {
                const token = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('token='))
                    ?.split('=')[1];

                if (!token) {
                    localStorage.clear();
                    dispatch(setIsAuth(false));
                    // await timerAlert(
                    //     'Error',
                    //     'Tu sesión ha expirado, por favor inicia sesión nuevamente',
                    //     'error',
                    //     2000
                    // );
                }
            }
        };

        checkTokenAndClearStorage();
    }, [dispatch]);
};

export default useClearLocalStorageOnSignal;
