import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const NavBarAdmin = ({ navBarIsOpen, handleBehaviorIcon, sessionData }) => {
    return (
        <header
            className={`nav_main_container admin ${
                navBarIsOpen ? 'is-active' : ''
            }`}
        >
            <nav>
                <Link href="/">
                    <Image
                        src="/logo.svg"
                        alt="logo"
                        width={50}
                        height={50}
                        className="brand_logo"
                    />
                </Link>

                <div className="buttons_container">
                    <div className="icon_user">
                        <Link
                            href="#"
                            onClick={handleBehaviorIcon}
                            className="d-flex align-items-center"
                        >
                            <p className="m-0 pt-3 me-3">
                                Hola{' '}
                                {sessionData.role === 'super_admin' && 'Super Admin'}
                                {sessionData.role === 'admin' && 'Admin'}
                                {sessionData.role === 'webmaster' && 'Web Master'}
                            </p>
                            <Image
                                src={`/icons/user.svg`}
                                alt="user"
                                width={36}
                                height={36}
                            />
                        </Link>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default NavBarAdmin;
