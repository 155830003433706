'use client';
import Image from 'next/image';
import '../../scss/components/nav.scss';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert, timerAlert } from '@/app/helpers/alerts';
import {
    setIsAuth,
    setIsLoading,
    setLinksPdfs,
    setNavbar,
    setOpenSideNav,
    setStepSideNav,
    setStoreIsActive,
} from '@/app/redux.actions/items.actions';
import { useRouter } from 'next/navigation';
import useSetIsAuth from '@/app/hooks/useSetIsAuth';
import { useEffect, useState } from 'react';
import getQuantity from '@/app/helpers/getQuantityCartItems';
import { removeAuthCookie } from '@/app/helpers/authCookie';
import { useStorage } from '@/app/hooks/useStorage';
import NavBarAdmin from './NavBarAdmin';
import NavBarUser from './NavBarUser';
import AxiosService from '@/app/services/AxiosServices';
import { formatLinksPdf } from '@/app/helpers/formatLinksPdf';
import { validateStoreState } from '@/app/hooks/useValidateStoreState';

const NavBar = () => {
    const {
        isAuth,
        navBarIsOpen,
        cart,
        delivery,
        sessionData,
        headerLoaded,
        isLoading,
        storeIsActive
    } = useSelector((state) => state.appStore);

    const [existLastBuy, setExistLastBuy] = useState(false);
    const [dataPdfs, setDataPdfs] = useState([]);

    useSetIsAuth();
    const storage = useStorage();
    const axiosService = new AxiosService();
    const dispatch = useDispatch();
    const router = useRouter();

    useEffect(() => {
        const fetchPdfs = async () => {
            try {
                const response = await axiosService.getData('/files/pdf/list');
                const urlFormated = await formatLinksPdf(response);
                dispatch(setLinksPdfs(urlFormated));
            } catch (error) {
                console.error('Error fetching Pdfs');
            }
        };

        fetchPdfs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // const [storeIsActive, setStoreIsActive] = useState(false);
    useEffect(() => {
        const getStateStore = async () => {
            const storeIsActive = await validateStoreState();
            dispatch(setStoreIsActive(storeIsActive));
        };
        getStateStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBehaviorIcon = (e) => {
        e.preventDefault();

        if (isAuth) {
            confirmAlert(
                '¿Estás seguro de finalizar la sesión?',
                'Finalizar sesión',
                'question'
            ).then((result) => {
                if (result.isConfirmed) {
                    timerAlert(
                        'Sesión finalizada',
                        'Se ha finalizado la sesión correctamente',
                        'success'
                    ).then(() => {
                        dispatch(setIsAuth(false));
                        storage.clearLocal();
                        removeAuthCookie();
                        window.location.href = '/auth';
                    });
                }
            });
        } else {
            router.push('/auth');
        }
    };

    const handleHamburguerClick = () => {
        // setIsActive(!navBarIsOpen);
        dispatch(setNavbar(!navBarIsOpen));
    };

    const handleShowCart = async (e) => {
        e.preventDefault();
        // si hay elementos en el carrito pasar al paso 2
        if (cart.length > 0) {
            dispatch(setOpenSideNav(true));
            dispatch(setStepSideNav(1));
        } else {
            await timerAlert(
                'Ups!!!',
                'No hay elementos en el carrito',
                'error'
            );
        }
    };

    const handleShowLastBuy = async (e) => {
        router.push('/?payment=ok');
    };

    const handleExistLastBuy = async (e) => {
        try {
            const response = await axiosService.getData('/auth/verifyTicketId');
            if (response && response === 'ok') {
                setExistLastBuy(true);
            }
        } catch (error) {
            console.log("Error verifying ticketId");
        }
    };

    return (
        <>
            {(sessionData.role === 'super_admin' ||
                sessionData.role === 'admin' ||
                sessionData.role === 'webmaster') &&
                headerLoaded && (
                    <NavBarAdmin
                        handleHamburguerClick={handleHamburguerClick}
                        handleBehaviorIcon={handleBehaviorIcon}
                        handleShowCart={handleShowCart}
                        cart={cart}
                        sessionData={sessionData}
                        isAuth={isAuth}
                    />
                )}

            {(sessionData.role === 'user' ||
                (!sessionData.role && headerLoaded)) && (
                <NavBarUser
                    handleHamburguerClick={handleHamburguerClick}
                    handleBehaviorIcon={handleBehaviorIcon}
                    handleShowCart={handleShowCart}
                    cart={cart}
                    sessionData={sessionData}
                    isAuth={isAuth}
                    handleExistLastBuy={handleExistLastBuy}
                    handleShowLastBuy={handleShowLastBuy}
                    existLastBuy={existLastBuy}
                    storeIsActive={storeIsActive}
                />
            )}
        </>
    );
};

export default NavBar;
