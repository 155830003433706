const mapItems = (items) => {
    return items.map((item) => {
        return {
            ...item,
            id: item?.id,
            images: item.images
                ? JSON.parse(item.images).map((img) => img.url)
                : [],
            background: item.background,
            name: item.name,
        };
    });
};

export default mapItems;