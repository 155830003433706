import {
    SET_ITEMS,
    SET_OPEN_SIDENAV,
    ADD_TO_CART,
    SET_STEP_SIDENAV,
    SET_TOTAL,
    REMOVE_FROM_CART,
    SET_DELIVERY,
    SET_IS_AUTH,
    SET_POSITION,
    SET_SESSION_DATA,
    SET_IS_LOADING,
    SET_NAVBAR,
    SET_CART,
    SET_USER_ORDER,
    SET_USER_ORDER_ID,
    SET_LOADED_HEADER,
    SET_USER_SUCCESS_ORDER,
    SET_LINKS_PDFS,
    SET_STORE_IS_ACTIVE,
} from '../redux.types/app.actions';



const calculateTotal = (cart) => {
    return cart.reduce((acc, item) => {
        const precio = parseFloat(item.precio) || 0;
        const quantity = parseInt(item.quantity, 10) || 1;
        return acc + precio * quantity;
    }, 0);
};


const initialState = {
    cart: [], // Carrtio de compras
    isAuth: false,  // Estado de autenticación
    items: [],   // Lista de productos
    loginPosition: 'signin', // Posición de la ventana de login
    successPaidOrder: [], // Ordenes pagadas
    openSideNav: false, // Estado del sideNav
    storeIsActive: true, // Estado de la tienda
    stepSideNav: 0, // Paso del sideNav
    linksPdfs: [], // Links de los pdfs
    headerLoaded: false, // Estado del header
    total: 0, // Total de la compra
    sessionData: {
        userId: null,
        role: null
    }, // Datos de la sesión
    isLoading: false, // Estado de carga
    navBarIsOpen: false, // Estado menu hamburguesa
    orderId: null, // Id de la orden
    delivery: null, // Datos de la entrega
};


export default function appReducer(state = initialState, action) {

    switch (action.type) {

        case SET_IS_AUTH:
            return {
                ...state,
                isAuth: action.payload,
            };

        case SET_STORE_IS_ACTIVE:
            return {
                ...state,
                storeIsActive: action.payload,
            };

        case SET_NAVBAR:
            return {
                ...state,
                navBarIsOpen: action.payload,
            }

        case SET_LOADED_HEADER:
            return {
                ...state,
                headerLoaded: action.payload,
            };

        case SET_SESSION_DATA:
            return {
                ...state,
                sessionData: action.payload,
            };

        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case SET_POSITION:
            return {
                ...state,
                loginPosition: action.payload,
            };

        case SET_USER_SUCCESS_ORDER:
            return {
                ...state,
                successPaidOrder: action.payload,
            };


        case SET_ITEMS:
            return {
                ...state,
                items: action.payload,
            };

        case SET_OPEN_SIDENAV:

            return {
                ...state,
                openSideNav: action.payload,
            };

            // case ADD_TO_CART: {
            //     console.log('state.cart', state.cart);

            //     const existingItemIndex = state.cart.findIndex(
            //         (cartItem) =>
            //             {
            //                 if (cartItem.size) {
            //                     console.log('El item tiene talla -->' , cartItem);
            //                 }  else {
            //                     console.log('El item NO tiene talla -->' , cartItem);
            //                 }
            //                 return cartItem.id === action.payload.id &&
            //                 cartItem.size === action.payload.size /* &&
            //                 cartItem.gender === action.payload.gender */
            //             }
            //     );

            //     console.log('existingItemIndex', existingItemIndex);
            
            //     let newCart;
            
            //     if (existingItemIndex >= 0) { 
            //         newCart = [...state.cart];
            //         const currentQuantity = newCart[existingItemIndex].quantity;
            //         const newQuantity = currentQuantity + action.payload.quantity;
                    
            //         if (newQuantity > 5) {
            //             // If new quantity exceeds 2, set it to 5
            //             newCart[existingItemIndex].quantity = 5;
            //         } else {
            //             newCart[existingItemIndex].quantity = newQuantity;
            //         }
            //     } else { 
            //         newCart = [...state.cart, action.payload];
            //     }
            
            //     return {
            //         ...state,
            //         cart: newCart,
            //     };
            // }

            case ADD_TO_CART: {
            
                // const existingItemIndex = state.cart.findIndex((cartItem) => {
                //     // Verificamos si el producto tiene talla
                //     if (cartItem.size || action.payload.size) {
                //         // Si ambos tienen talla, los comparamos por id y talla
                //         return (
                //             cartItem.id === action.payload.id &&
                //             cartItem.size === action.payload.size
                //         );
                //     } else {
                //         // Si ninguno tiene talla, solo comparamos por id
                //         return cartItem.id === action.payload.id;
                //     }
                // });

                const existingItemIndex = state.cart.findIndex((cartItem) => {
                    // Caso 1: Si el producto tiene talla y género
                    if (cartItem.size && cartItem.gender && action.payload.size && action.payload.gender) {
                        return (
                            cartItem.id === action.payload.id &&
                            cartItem.size === action.payload.size &&
                            cartItem.gender === action.payload.gender
                        );
                    }
                    // Caso 2: Si el producto tiene solo talla (sin género)
                    else if (cartItem.size && action.payload.size && !cartItem.gender && !action.payload.gender) {
                        return cartItem.id === action.payload.id && cartItem.size === action.payload.size;
                    }
                    // Caso 3: Si el producto no tiene ni talla ni género
                    else if (!cartItem.size && !action.payload.size && !cartItem.gender && !action.payload.gender) {
                        return cartItem.id === action.payload.id;
                    }
                    return false;
                });


            
                console.log('existingItemIndex', existingItemIndex);
            
                let newCart;
            
                if (existingItemIndex >= 0) {
                    // El item ya está en el carrito, actualizamos la cantidad
                    newCart = [...state.cart];
                    const currentQuantity = newCart[existingItemIndex].quantity;
                    const newQuantity = currentQuantity + action.payload.quantity;
            
                    // Limitar la cantidad máxima a 5
                    if (newQuantity > 5) {
                        newCart[existingItemIndex].quantity = 5;
                    } else {
                        newCart[existingItemIndex].quantity = newQuantity;
                    }
                } else {
                    // El item no está en el carrito, lo agregamos
                    newCart = [...state.cart, action.payload];
                }
            
                return {
                    ...state,
                    cart: newCart,
                };
            }

        case SET_CART: {

            const newCart = action.payload.cart;
            const newTotal = action.payload.total;
            const orderId = action.payload.orderId;

            return {
                ...state,
                cart: newCart,
                total: newTotal,
                orderId,
            };
        }

        case REMOVE_FROM_CART: {
            const newCart = state.cart.filter(
                (cartItem) =>
                    !(cartItem.id === action.payload.id &&
                      cartItem.size === action.payload.size &&
                      cartItem.gender === action.payload.gender)
            );

            return {
                ...state,
                cart: newCart,
                // total: calculateTotal(newCart),
            };
        }

        case SET_DELIVERY:
            return {
                ...state,
                delivery: action.payload
            };

        case SET_STEP_SIDENAV:
            return {
                ...state,
                stepSideNav: action.payload,
            };


        case SET_TOTAL:
            return {
                ...state,
                total: action.payload,
            };

        case SET_USER_ORDER_ID :
            return {
                ...state,
                orderId: action.payload,
            };

            
        case SET_LINKS_PDFS:
            return {
                ...state,
                linksPdfs: action.payload,
            };

        default:
            return state;
    }
}
