import { jwtVerify } from 'jose';

export async function decryptJWT(token) {
    const secretKey = new TextEncoder().encode(process.env.NEXT_JWT_SECRET);

    if (!secretKey) {
        return false;
    }

    try {
        const decodedToken = await jwtVerify(token, secretKey, { algorithms: ['HS256'] });
        return decodedToken;
    } catch (error) {
        return false;
    }
}