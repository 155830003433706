'use client';
import { removeAuthCookie } from "../helpers/authCookie";
import { useStorage } from "./useStorage";

const useUnautorized = () => {
    const storage = useStorage();

    storage.clearLocal();
    removeAuthCookie();
    setTimeout(() => {
        window.location.href = "/auth";
    }, 2000);
}

export default useUnautorized