import { useRouter, useSearchParams } from 'next/navigation';
import AxiosService from '../services/AxiosServices';

const getVoucherInfo = async (data) => {
    const axiosService = new AxiosService();
    const userOrders = await axiosService.createData( '/orders/getVoucherInfo', data );

    // const searchParams = useSearchParams();
    // const paymentId = searchParams.get('payment');
    // const router = useRouter();

    return userOrders;
};

export default getVoucherInfo;
