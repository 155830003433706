
export const SET_SESSION_DATA = 'SET_SESSION';

export const SET_IS_LOADING = 'SET_IS_LOADING';

export const SET_IS_AUTH = 'SET_IS_AUTH';

export const SET_STORE_IS_ACTIVE = 'SET_STORE_IS_ACTIVE';

export const SET_NAVBAR =  'SET_NAVBAR';

export const SET_LOADED_HEADER = 'SET_LOADED_HEADER';

export const SET_POSITION = 'SET_POSITION';

export const SET_USER_SUCCESS_ORDER = 'SET_USER_SUCCESS_ORDER';

export const SET_ITEMS = 'SET_ITEMS';

export const SET_OPEN_SIDENAV = 'SET_OPEN_SIDENAV';

export const ADD_TO_CART = 'ADD_TO_CART';

export const SET_CART = 'SET_CART';

export const SET_USER_ORDER_ID = 'SET_USER_ORDER_ID';

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const SET_DELIVERY = 'SET_DELIVERY';

export const SET_STEP_SIDENAV = 'SET_STEP_SIDENAV';

export const SET_LINKS_PDFS = 'SET_LINKS_PDFS';

export const SET_TOTAL = 'SET_TOTAL';

