import axiosInstance from './AxiosInterceptor.js';

export default class AxiosService {
    async getData(lastUrl) {

        //retraso de 1 segundo para simular carga
        return axiosInstance.get(lastUrl).then((response) => {
            return response?.data;
        });
    }

    async getDataById(lastUrl, id) {
        return axiosInstance.get(`${lastUrl}/${id}`).then((response) => {
            return response?.data;
        });
    }

    async getDataBody(lastUrl, data) {
        return axiosInstance.get(lastUrl, { data }).then((response) => {
            return response;
        });
    }

    async createData(lastUrl, data) {
        return axiosInstance.post(lastUrl, data).then((response) => {
            return response;
        });
    }

    async createFormData(lastUrl, data) {
        return axiosInstance.post(lastUrl, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            return response;
        });
    }

    async updateData(lastUrl, id, data) {
        return axiosInstance.put(`${lastUrl}/${id}`, data).then((response) => {
            return response;
        });
    }

    async updateFormData(lastUrl, id, data) {
        return axiosInstance.put(`${lastUrl}/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            return response;
        });
    }

    async updatePatchData(lastUrl, id, data) {
        return axiosInstance.patch(`${lastUrl}/${id}`, data).then((response) => {
            return response;
        });
    }

    async updateFormPatchData(lastUrl, id, data) {
        return axiosInstance.patch(`${lastUrl}/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            return response;
        });
    }

    async updateDataBody(lastUrl, data) {
        return axiosInstance.put(lastUrl, data).then((response) => {
            return response;
        });
    }

    async deleteData(lastUrl, id) {
        return axiosInstance.delete(`${lastUrl}/${id}`).then((response) => {
            return response;
        });
    }
}
