'use client';
import { usePathname } from 'next/navigation';
import '../../scss/components/footer.scss';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { validateStoreState } from '@/app/hooks/useValidateStoreState';
import { useDispatch } from 'react-redux';
import { setStoreIsActive } from '@/app/redux.actions/items.actions';

const Footer = () => {
    // Validar si la ruta es el home
    const pathname = usePathname();
    const isHome = pathname === '/';
    const { sessionData, linksPdfs, storeIsActive } = useSelector((state) => state.appStore);
    const dispatch = useDispatch(); 
    
    useEffect(() => {
        const getStateStore = async () => {
            const storeIsActive = await validateStoreState();
            dispatch(setStoreIsActive(storeIsActive));
        };
        getStateStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {sessionData.role === 'super_admin' ||
            sessionData.role === 'admin' ||
            sessionData === 'webmaster' ? null : (
                <footer
                    style={{
                        paddingBottom: !storeIsActive ? '85px' : '0px', // Aplica padding cuando storeIsActive es true
                    }}
                >
                    <div className="footer_container">
                        {isHome ? (
                            <div className="top_container">
                                <h4 className="title">
                                    Lleva nuestra marca con estilo y
                                    responsabilidad
                                </h4>
                                <p className="descr">
                                    Teniendo en cuenta que eres nuestro
                                    embajador de marca y llevas contigo la
                                    responsabilidad de representar con orgullo
                                    el Banco más grande de Latinoamérica,
                                    queremos que tengas en cuenta las siguientes
                                    condiciones de uso que son de obligatorio
                                    cumplimiento al momento que decides adquirir
                                    los productos ofrecidos, y que hacen parte
                                    de las obligaciones y responsabilidades que
                                    tienes como trabajador de Itaú.
                                </p>
                                <p
                                    className="view_more"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (linksPdfs['documento_1'][0].url) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_1'][0].url} `,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    Ver Más
                                </p>
                            </div>
                        ) : null}

                        <div className="main_container">
                            <p className="title">Tienda naranja © 2024</p>
                            <div className="footer_links">
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (linksPdfs['documento_4'][0].url) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_4'][0].url} `,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    Política tratamiento de datos personales
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (linksPdfs['documento_5'][0].url) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_5'][0].url} `,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    Políticas de privacidad
                                </a>

                                <Link
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (linksPdfs['documento_4'][0].url) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_4'][0].url} `,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    Políticas de cookies
                                </Link>
                                <Link
                                    href={`${process.env.NEXT_URL_DOMAIN}/sitemap.xml`}
                                >
                                    Mapa del sitio
                                </Link>
                            </div>
                        </div>
                    </div>
                </footer>
            )}
        </>
    );
};

export default Footer;
