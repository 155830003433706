
export const formatLinksPdf = async (links) => {
    return links.reduce((acc, link) => {
        const { ubication, id, name, file } = link;

        // Si la ubicación no existe en el objeto acumulador, inicializarla como un array vacío
        if (!acc[ubication]) {
            acc[ubication] = [];
        }

        // Construir el objeto de link si hay un archivo
        if (file) {
            acc[ubication].push({
                id,
                name: name || 'Unnamed', // Proporciona un nombre por defecto si falta
                url: `files/pdf/${encodeURIComponent(file)}`, // Parametriza la URL del archivo
            });
        } else {
            // Maneja enlaces sin archivos, si es necesario
            acc[ubication].push({
                id,
                name: name || 'Unnamed',
                url: null, // O puedes dejarlo vacío o poner una URL predeterminada
            });
        }

        return acc;
    }, {});
};