import axios from 'axios';
import { timerAlert } from '../helpers/alerts';
import { removeAuthCookie } from '../helpers/authCookie';
import useUnautorized from '../hooks/useUnautorized';

// Crear una instancia de Axios
const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Agregar un interceptor de solicitud
axiosInstance.interceptors.request.use(
  (config) => {
    // Obtener el token de alguna fuente (e.g., localStorage)
    const token = localStorage.getItem('token');

    // Si el token existe, agregarlo a los encabezados
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // No olvidar devolver la configuración para que la solicitud continúe
    return config;
  },
  (error) => {
    // Manejar errores de solicitud
    return Promise.reject(error);
  }
);

// Agregar un interceptor de respuesta (opcional)
axiosInstance.interceptors.response.use(
  (response) => {
    // Procesar la respuesta

    return response;
  },
  async (error) => {
    // Manejar errores de respuesta

    // console.log('Error', error);

    if (error.response && error.response.status === 401) {
      // Manejar errores de autenticación
      await timerAlert('Error', 'La sesión ha expirado', 'error', 2000);
      useUnautorized();
    }
    
    if (error.response ) {
      const errorMessage = error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Error';

      await timerAlert('Error al procesar la solicitud', errorMessage, 'error', 6000);
    }

    // await timerAlert('Error', 'Error al procesar la solicitud', 'error', 2000);

    



    // if (error.response && error.response.status === 401 || error.response.status === 404) {
    //   // Manejar errores de autenticación
    //   timerAlert('Error', 'No autorizado', 'error', 2000).then(() => {
    //     // redirigir a la página de inicio de sesión
    //     window.location.href = '/auth';
    //   });
    // }

    // if (error.response && error.response.status === 500) {
    //   // Manejar errores de servidor
    //   timerAlert('Error', 'Error al procesar la solicitud', 'error', 2000);
    // }



    // return Promise.reject(error);
  }
);

export default axiosInstance;