'use client';
import { useEffect, useState } from 'react';
import '../../scss/components/_disableBanner.scss';
import { validateStoreState } from '@/app/hooks/useValidateStoreState';
import { useDispatch } from 'react-redux';
import { setStoreIsActive } from '@/app/redux.actions/items.actions';
import { useSelector } from 'react-redux';

const DisableBanner = () => {
  const dispatch = useDispatch();
  const { storeIsActive } = useSelector((state) => state.appStore);

    useEffect(() => {
        const getStateStore = async () => {
            const storeIsActive = await validateStoreState();
            dispatch(setStoreIsActive(storeIsActive));
        };
        getStateStore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!storeIsActive && (
                <div className="disable_container">
                    <p>Esta tienda esta deshabilitada</p>
                </div>
            )}
        </>
    );
};

export default DisableBanner;
