
import mapItems from './formatItems';

const updateCartFromDB = async (userOrders) => {
   
    
    if (userOrders && userOrders.length > 0) {

        const firstOrder = userOrders[0];
        if (firstOrder.products && firstOrder.products.length > 0) {
            const cartToAdd = firstOrder.products.map((el) => {
                const size = el.product.sizes ? el.product.sizes.find((size) => size.id === el.sizeId) : null;
                return {
                    ...el.product,
                    quantity: el.quantity,
                    sizeId: el.sizeId,
                    size: size ? size.sizeName : null, // Asegúrate de que 'size' sea una cadena o null
                    gender: el.gender ? el.gender : null,
                };
            });
            const newCart = mapItems(cartToAdd);
            return newCart;

            
        } else {
            return;
        }
    } else {
        return;
    }
};

export default updateCartFromDB;
