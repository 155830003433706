import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    SET_DELIVERY,
    SET_ITEMS,
    SET_OPEN_SIDENAV,
    SET_STEP_SIDENAV,
    SET_IS_AUTH,
    SET_POSITION,
    SET_SESSION_DATA,
    SET_IS_LOADING,
    SET_NAVBAR,
    SET_CART,
    SET_TOTAL,
    SET_USER_ORDER_ID,
    SET_LOADED_HEADER,
    SET_USER_SUCCESS_ORDER,
    SET_LINKS_PDFS,
    SET_STORE_IS_ACTIVE,
} from '../redux.types/app.actions';

export const setSessionData = (userId, role) => {
    return {
        type: SET_SESSION_DATA,
        payload: { userId, role },
    };
};

export const setIsLoading = (isLoading) => {
    return {
        type: SET_IS_LOADING,
        payload: isLoading,
    };
};

export const setIsAuth = (isAuth) => {
    return {
        type: SET_IS_AUTH,
        payload: isAuth,
    };
};

export const setStoreIsActive = (state) => {
    return {
        type: SET_STORE_IS_ACTIVE,
        payload: state,
    };
};

export const setNavbar = (state) => {
    return {
        type: SET_NAVBAR,
        payload: state,
    };
};

export const setLoadedHeader = (state) => {
    return {
        type: SET_LOADED_HEADER,
        payload: state,
    };
};

export const setPositionAction = (position) => {
    return {
        type: SET_POSITION,
        payload: position,
    };
};

export const setItems = (items) => {
    return {
        type: SET_ITEMS,
        payload: items,
    };
};

export const addToCart = (cart) => {
    return {
        type: ADD_TO_CART,
        payload: cart,
    };
};

export const setCart = (cart, total, orderId) => ({
    type: SET_CART,
    payload: { cart, total, orderId },
});

export const setUserSuccessOrder = (
    order,
    transactionDetails,
    buyer,
    message
) => {
    const orderData = {
        order,
        transactionDetails,
        buyer,
        message,
    };

    return {
        type: SET_USER_SUCCESS_ORDER,
        payload: orderData,
    };
};

export const setDelivery = (delivery) => {
    return {
        type: SET_DELIVERY,
        payload: delivery,
    };
};

export const setOpenSideNav = (openSideNav) => {
    return {
        type: SET_OPEN_SIDENAV,
        payload: openSideNav,
    };
};

export const setLinksPdfs = (links) => {
    return {
        type: SET_LINKS_PDFS,
        payload: links,
    };
};

export const setStepSideNav = (step) => {
    return {
        type: SET_STEP_SIDENAV,
        payload: step,
    };
};

export const removeFromCart = (id, size, gender) => {
    return {
        type: REMOVE_FROM_CART,
        payload: { id, size, gender },
    };
};

export const setTotal = (total) => {
    return {
        type: SET_TOTAL,
        payload: total,
    };
};

export const setUserOrderId = (orderId) => {
    return {
        type: SET_USER_ORDER_ID,
        payload: orderId,
    };
};
