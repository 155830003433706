import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/components/disable-banner/DisableBanner.jsx");
;
import(/* webpackMode: "eager" */ "/build/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/imports/BootstrapImport.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/layout/footer/Footer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/layout/navbar/NavBar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/build/app/store.provider.js");
;
import(/* webpackMode: "eager" */ "/build/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/build/node_modules/bootstrap/dist/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/build/app/scss/index.scss");
