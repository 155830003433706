const getQuantity = (cart) => {
    let quantity = 0;
    cart.forEach((item) => {
        quantity += item.quantity;
    });
    if(quantity == 0 ){
        return '';
    }
    return quantity;
};

export default getQuantity;