import getQuantity from '@/app/helpers/getQuantityCartItems';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

const NavBarUser = ({
    navBarIsOpen,
    handleHamburguerClick,
    handleBehaviorIcon,
    isAuth,
    handleShowCart,
    cart,
    handleShowLastBuy,
    existLastBuy,
    handleExistLastBuy,
    storeIsActive,
}) => {
    useEffect(() => {
        if (isAuth) {
            handleExistLastBuy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    const { linksPdfs } = useSelector((state) => state.appStore);

    return (
        <header
            className={`nav_main_container ${navBarIsOpen ? 'is-active' : ''}`}
        >
            <nav>
                <button
                    className="nav_button"
                    type="button"
                    onClick={handleHamburguerClick}
                >
                    <span>Menú</span>
                    <div
                        className={`hamburger hamburger--collapse ${
                            navBarIsOpen ? 'is-active' : ''
                        }`}
                    >
                        <div className="hamburger-box">
                            <div className="hamburger-inner"></div>
                        </div>
                    </div>
                </button>

                <Link href="/">
                    <Image
                        src="/logo.svg"
                        alt="logo"
                        width={50}
                        height={50}
                        className="brand_logo"
                    />
                </Link>

                <ul className="nav_links">
                    <li>
                        <Link
                            href="/#list_items"
                            onClick={handleHamburguerClick}
                        >
                            Artículos
                        </Link>
                    </li>
                    <li>
                        <Link href="/#faq" onClick={handleHamburguerClick}>
                            Preguntas frecuentes
                        </Link>
                    </li>
                    <div className="dropdown">
                        <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Información
                        </a>
                        {/* 

http://localhost:3002/files/pdf/COTIZACI%C3%83%C2%93N%20SALIDA%20PEDAGOGICA.pdf
*/}
                        <ul className="dropdown-menu">
                            <li>
                                <Link
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                        handleHamburguerClick();
                                        if (linksPdfs['documento_1'][0].url) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_1'][0].url} `,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    Lineamientos de uso
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                        handleHamburguerClick();
                                        if (linksPdfs['documento_2'][0].url) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_2'][0].url} `,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    Entrega y distribución
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                        handleHamburguerClick();
                                        if (linksPdfs['documento_3'][0].url) {
                                            window.open(
                                                `${process.env.NEXT_PUBLIC_API_URL}/${linksPdfs['documento_3'][0].url} `,
                                                '_blank'
                                            );
                                        }
                                    }}
                                >
                                    Garantías
                                </Link>
                            </li>
                        </ul>
                    </div>
                </ul>

                <div className="buttons_container">
                    <div className="logo_2">
                        <Image
                            src="/logo_2.png"
                            alt="logo"
                            width={643}
                            height={395}
                        />
                    </div>

                    <div className="icon_user">
                        <Link href="#" onClick={handleBehaviorIcon}>
                            {isAuth ? (
                                <>
                                    <Image
                                        src={`/icons/user_logged.svg`}
                                        alt="user"
                                        width={36}
                                        height={36}
                                        data-tooltip-id="tooltip_close_session"
                                        data-tooltip-content="Cerrar sesión"
                                        data-tooltip-place="bottom"
                                    />
                                    <Tooltip id="tooltip_close_session" />
                                </>
                            ) : (
                                <>
                                    <Image
                                        src={`/icons/user.svg`}
                                        alt="user"
                                        width={36}
                                        height={36}
                                        data-tooltip-id="tooltip_sign_in"
                                        data-tooltip-content="Iniciar sesión"
                                        data-tooltip-place="bottom"
                                    />
                                    <Tooltip id="tooltip_sign_in" />
                                </>
                            )}
                        </Link>
                    </div>

                    {storeIsActive && (
                        <>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="23.5"
                                viewBox="0 0 2 23.5"
                            >
                                <line
                                    id="Line_6"
                                    data-name="Line 6"
                                    y2="21.5"
                                    transform="translate(1 1)"
                                    fill="none"
                                    stroke="#5f5f5f"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                />
                            </svg>
                            <div className="icon_cart">
                                <Link
                                    href="#"
                                    onClick={handleShowCart}
                                    data-tooltip-id="tooltip_cart"
                                    data-tooltip-content="Ver carrito"
                                    data-tooltip-place="bottom"
                                >
                                    <div className="cart_icon_container">
                                        <Image
                                            className="cart_icon"
                                            src="/icons/cart.svg"
                                            alt="cart"
                                            width={38}
                                            height={38}
                                        />
                                        {getQuantity(cart) !== '' ? (
                                            <p className="cart_counter">
                                                {getQuantity(cart)}
                                            </p>
                                        ) : null}
                                    </div>
                                </Link>
                                <Tooltip id="tooltip_cart" />
                            </div>{' '}
                        </>
                    )}

                    {existLastBuy && (
                        <div className="icon_cart last_buy">
                            <div
                                onClick={() => {
                                    handleShowLastBuy();
                                }}
                            >
                                <div
                                    className="cart_icon_container last_buy"
                                    data-tooltip-id="tooltip_last_buy"
                                    data-tooltip-content="Estado de tu última compra"
                                    data-tooltip-place="top"
                                >
                                    <Image
                                        className="last_buy_icon"
                                        src="/icons/shopping-bag.svg"
                                        alt="cart"
                                        width={30}
                                        height={30}
                                    />
                                    <p className="cart_counter">
                                        <Image
                                            className="last_buy_icon_alert"
                                            src="/icons/exclamation.svg"
                                            alt="cart"
                                            width={16}
                                            height={16}
                                        />
                                    </p>
                                    <Tooltip id="tooltip_last_buy" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default NavBarUser;
